import React, {createContext, useContext, useState} from 'react';
import {TourProvider, useTour} from '@reactour/tour';
import {TutorialCloseButton} from 'contexts/helpers/TutorialCloseButton';
import {TutorialPreviousButton} from 'contexts/helpers/TutorialPreviousButton';
import {useTheme} from 'styled-components';

export enum Tutorial {
    ROOM,
    MATERIAL,
}

interface TutorialContextType {
    tutorial?: Tutorial;
    clearSteps?: () => void;
    addRoomTutorial?: () => void;
    addMaterialTutorial?: () => void;
}

const TutorialContext = createContext<TutorialContextType>({});

export const useTutorialContext = () => useContext(TutorialContext);

const TutorialComponent = ({children}: {children: JSX.Element}) => {
    const {setSteps, setIsOpen} = useTour();
    const [tutorial, setTurorial] = useState<Tutorial>();

    const clearSteps = () => {
        setSteps([]);
        setIsOpen(false);
    };

    const addRoomTutorial = () => {
        setTurorial(Tutorial.ROOM);
        setSteps([
            {
                selector: '#add_room',
                content:
                    'Click here to add a room and set your preferred cabinet defaults',
                position: 'right',
            },
        ]);
    };

    const addMaterialTutorial = () => {
        setTurorial(Tutorial.MATERIAL);
        setSteps([
            {
                selector: '.material_search_container',
                content: (
                    <>
                        <p>Welcome to the new material search</p>
                        <p>
                            We&apos;ve made it easier to find the materials
                            you&apos;re after
                        </p>
                    </>
                ),
                position: 'bottom',
                highlightedSelectors: [
                    '.material_search_container .material-list',
                    '.material_search_container',
                ],
                resizeObservables: [
                    '.material_search_container .material-list',
                ],
            },
            {
                selector: '.material_search',
                content: (
                    <>
                        <p>
                            Type your desired material here. You can search for{' '}
                        </p>
                        <ul>
                            <li>Colour name</li>
                            <li>Brand name</li>
                            <li>Finish name</li>
                            <li>Thickness &amp; Substrate</li>
                            <li>Combination of all of the above</li>
                        </ul>
                    </>
                ),
                position: 'bottom',
                highlightedSelectors: [
                    '.material_search .material-list',
                    '.material_search',
                ],
                resizeObservables: ['.material_search .material-list'],
            },
            {
                selector: '.material_edge_search',
                content:
                    'You can search the same parameters for your edge materials',
                position: 'bottom',
                highlightedSelectors: [
                    '.material_edge_search .material-list',
                    '.material_edge_search',
                ],
                resizeObservables: ['.material_edge_search .material-list'],
            },
            {
                selector: '.advanced_material_editor',
                content:
                    'To return to the previous method for material search, click the Advanced Editor button',
                position: 'bottom',
            },
        ]);
    };

    return (
        <TutorialContext.Provider
            value={{
                tutorial,
                clearSteps,
                addRoomTutorial,
                addMaterialTutorial,
            }}>
            {children}
        </TutorialContext.Provider>
    );
};

export const TutorialProvider = ({children}: {children: JSX.Element}) => {
    const theme = useTheme();

    return (
        <TourProvider
            steps={[]}
            styles={{
                maskWrapper: (base) => ({
                    ...base,
                    opacity: 0.2,
                }),
                maskArea: (base) => ({...base, rx: 12}),
                popover: (base) => ({
                    ...base,
                    marginLeft: '15px',
                    padding: '15px',
                    borderRadius: '25px',
                    background: 'white',
                    // background: lightenDarkenColor(color, ratio),
                    border: `3px solid ${theme.colors.secondary.main}`,
                    width: '310px',
                    fontSize: '.85em',
                }),
                badge: () => ({display: 'none'}),
                dot: () => ({display: 'none'}),
                controls: (base) => ({
                    ...base,
                    margin: 0,
                    justifyContent: 'center',
                }),
            }}
            onClickMask={() => {
                // Do nothing on click on mask
            }}
            scrollSmooth={true}
            showCloseButton={false}
            disableFocusLock={true}
            disableKeyboardNavigation={['left', 'right']}
            disableDotsNavigation={true}
            prevButton={TutorialPreviousButton}
            nextButton={TutorialCloseButton}
            inViewThreshold={{x: 70, y: 0}}>
            <TutorialComponent>{children}</TutorialComponent>
        </TourProvider>
    );
};
