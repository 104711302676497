import React, {useCallback} from 'react';
import {hexToRgb} from 'shared/helpers';
import styled from 'styled-components';

export interface ButtonInterface {
    setIsOpen?: (isOpen: boolean) => void;
    setCurrentStep?: (step: number) => void;
    currentStep?: number;
    stepsLength?: number;
}

export const StyledTutorialButton = styled.div`
    position: initial;
    border-radius: 25px;
    border: 5px solid
        rgba(${({theme}) => hexToRgb(theme.colors.secondary.main)}, 0.5);
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin: 15px 5px 0;

    span {
        background: ${({theme}) => theme.colors.secondary.main};
        display: block;
        padding: 3px 20px;
        border-radius: 25px;
        font-size: 1em;
    }

    transition: border-color 200ms;

    :hover {
        border-color: rgba(
            ${({theme}) => hexToRgb(theme.colors.secondary.main)},
            0.8
        );
    }
`;

export const TutorialPreviousButton = ({
    setCurrentStep,
    currentStep,
    stepsLength,
}: ButtonInterface) => {
    const handleClick = useCallback(
        () => setCurrentStep(currentStep - 1),
        [setCurrentStep, currentStep]
    );

    if (stepsLength > 1 && currentStep > 0) {
        return (
            <StyledTutorialButton onClick={handleClick}>
                <span>Prev</span>
            </StyledTutorialButton>
        );
    }

    return null;
};
