import React, {
    createContext,
    useContext,
    useRef,
    useReducer,
    useState,
} from 'react';
import {cloneDeep} from 'lodash';
import {Alert} from 'react-bootstrap';
import toast from 'react-hot-toast';
import {Icon} from 'shared/helpers';

export interface Message {
    fields: string[];
    message: string;
    onlyField?: boolean;
}

interface IndexedMessage {
    [key: number]: Message[];
}

interface Messages {
    errors: IndexedMessage | Message[];
    warnings: Message[];
    infos: Message[];
    successes: Message[];
}

interface ActionType {
    type: 'error' | 'warn' | 'info' | 'success';
    messages: Message[] | IndexedMessage;
}

interface Notification {
    variant: 'success' | 'danger' | 'info' | 'warning';
    message: string | JSX.Element | string[];
}

interface NotificationContextType {
    messages?: Messages;
    notify?: (notifications: Notification[]) => void;
    addMessages?: React.Dispatch<ActionType>;
    MESSAGE_TYPES?: {[key: string]: string};
}

export const NotificationContext: React.Context<NotificationContextType> =
    createContext({});

export const useNotificationContext = () => {
    return useContext(NotificationContext);
};

export const NotificationProvider = ({
    children,
}: {
    children: JSX.Element;
}): JSX.Element => {
    // NOTE: not yet moving this to enum as this is used in multiple places
    const [MESSAGE_TYPES] = useState({
        ERROR: 'error',
        WARN: 'warn',
        INFO: 'info',
        SUCCESS: 'success',
    });

    const [messages, addMessages] = useReducer(
        (state: Messages, action: ActionType): Messages => {
            const updatedState = cloneDeep(state);

            action.type = action.type ? action.type : null;
            switch (action.type) {
                case MESSAGE_TYPES.ERROR:
                    if (Array.isArray(action.messages)) {
                        updatedState.errors = action.messages;
                    } else {
                        updatedState.errors = {
                            ...updatedState.errors,
                            ...action.messages,
                        } as IndexedMessage;
                    }
                    break;

                case MESSAGE_TYPES.WARN:
                    updatedState.warnings = action.messages as Message[];
                    break;

                case MESSAGE_TYPES.INFO:
                    updatedState.infos = action.messages as Message[];
                    break;

                case MESSAGE_TYPES.SUCCESS:
                    updatedState.successes = action.messages as Message[];
                    break;
            }

            return updatedState;
        },
        {errors: [], warnings: [], infos: [], successes: []} as Messages
    );
    const style = useRef({
        width: '300px',
        height: 'auto',
        minHeight: '70px',
        borderRadius: '9px',
        paddingLeft: '10px',
    }).current;

    const notify = (notifications: Notification[], duration = 3000) => {
        notifications.forEach((notification) => {
            const {variant, message} = notification;

            let icon: JSX.Element;

            if (variant === 'success') {
                icon = <Icon iconName="Button-Tick.svg" />;
            } else if (variant === 'danger') {
                icon = <Icon iconName="Button-Error.svg" />;
            } else {
                icon = <Icon iconName="Button-Exclamation.svg" />;
            }

            let content = message;

            if (Array.isArray(message)) {
                content = (
                    <ul>
                        {message.map((m, k) => (
                            <li key={k}>{m}</li>
                        ))}
                    </ul>
                );
            }

            toast.custom(
                (t) => {
                    return (
                        <Alert
                            className="cbc-toast"
                            style={style}
                            variant={variant}
                            onClose={() => toast.remove(t.id)}
                            dismissible>
                            <div>{icon}</div>
                            <div>{content}</div>
                        </Alert>
                    );
                },
                {duration, position: 'bottom-right'}
            );
        });
    };

    return (
        <NotificationContext.Provider
            value={{notify, messages, addMessages, MESSAGE_TYPES}}>
            {children}
        </NotificationContext.Provider>
    );
};
