import React, {
    createContext,
    useState,
    useContext,
    useReducer,
    useLayoutEffect,
    useEffect,
    useRef,
    PropsWithChildren,
} from 'react';
import {useUserProfile, useCountries} from 'hooks';
import ReactGA from 'react-ga4';

import {
    User,
    invalidateUser,
    useGetUserQuery,
} from 'components/customer/Auth/store/userSlice';
import {Loader} from 'shared/helpers';
import {useAppDispatch} from 'store/customer';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

type ApplicationContextType = {
    smallLayout?: boolean;
    userProfile?: User;
    isMobileMenuVisible?: string;
    setIsMobileMenuVisible?: (isVisible: boolean) => void;
    setDisabledProperties?: (action: {type: string; payload: any[]}) => void;
    stateLabel: string;
    places: {
        Countries: Record<
            string,
            {
                id: number;
                name: string;
            }[]
        >;
    };
    userLevel: string;
    alternateCartContent: React.ReactElement;
    setAlternateCartContent: React.Dispatch<
        React.SetStateAction<React.ReactElement>
    >;
    setIsLeftDashboardButtonVisible: (v: boolean) => void;
};

export const ApplicationContext: React.Context<ApplicationContextType> =
    createContext({});

export const useAppContext = () => useContext(ApplicationContext);

const STANDARD_SIZE = 1440;

/**
 *
 * This component is used for persisting application data during a session. This component sits on the highest level
 * in component hierarchy so any data persisted in this component is available throughout the application.
 *
 * @param {object} children
 *
 * @return {ApplicationContext.Provider}
 */
export const ApplicationProvider = ({children}: PropsWithChildren<object>) => {
    const dispatch = useAppDispatch();

    const [isLeftDashboardButtonVisible, setIsLeftDashboardButtonVisible] =
        useState<boolean>(false);
    const [smallLayout, setSmallLayout] = useState<boolean>(false);
    const [isMobileMenuVisible, setIsMobileMenuVisible] =
        useState<boolean>(true);
    const [cartItems, setCartItems] = useState([{}]);
    const {data: userProfile, isLoading} = useGetUserQuery();
    const [refreshDashboard, setRefreshDashboard] = useState({status: false});
    const [successDialog, setSuccessDialog] = useState([]);
    const [sideBarOverlays, setSideBarOverlays] = useState<boolean>(false);
    const [resultReceived, setResultReceived] = useState(false);
    const [searchOnText, setsearchOnText] = useState('');
    const [searchOnButton, setsearchOnButton] = useState('');
    const [isSearching, setisSearching] = useState(false);
    const searchInput = useRef();
    const isSmallDevice = isDeviceSmall();
    const [alternateCartContent, setAlternateCartContent] =
        useState<React.ReactElement>(null);

    // state for disabled proeprties by manufacturer
    const [disableProperties, setDisabledProperties] = useReducer(
        (currentState, action) => {
            switch (action.type) {
                // TODO: when we have more unavailable fields in future then use merge (lodash) or some other trick to merge multiple data to current state
                case 'add':
                    return action.payload;
                default:
                    return currentState;
            }
        },
        []
    );

    // state for user profile
    const userLevel = useUserProfile(userProfile);
    const {places, stateLabel} = useCountries(userProfile);

    useEffect(
        () =>
            ReactGA.set({
                user_properties:
                    userProfile &&
                    userProfile.hasOwnProperty('analyticsMetrics')
                        ? userProfile.analyticsMetrics
                        : {},
            }),
        [userProfile]
    );

    useLayoutEffect(() => {
        const availableWidth: number = document.body.clientWidth;

        if (!isSmallDevice && availableWidth <= STANDARD_SIZE) {
            setSmallLayout(true);
        }
    }, [isSmallDevice]);

    const refreshUserData = () => {
        dispatch(invalidateUser());
    };

    if (isLoading) {
        return <Loader loader={isLoading} />;
    }

    return (
        <ApplicationContext.Provider
            value={{
                userProfile,
                places,
                stateLabel,
                userLevel,
                refreshUserData,
                cartItems,
                setCartItems,
                refreshDashboard,
                setRefreshDashboard,
                successDialog,
                setSuccessDialog,
                sideBarOverlays,
                setSideBarOverlays,
                resultReceived,
                setResultReceived,
                searchOnText,
                setsearchOnText,
                searchOnButton,
                setsearchOnButton,
                isSearching,
                setisSearching,
                searchInput,
                isLeftDashboardButtonVisible,
                setIsLeftDashboardButtonVisible,
                disableProperties,
                setDisabledProperties,
                smallLayout,
                isMobileMenuVisible,
                setIsMobileMenuVisible,
                setAlternateCartContent,
                alternateCartContent,
            }}>
            <section
                style={{
                    height: '100%',
                    paddingLeft: 0,
                    paddingRight: 0,
                }}>
                {children}
            </section>
        </ApplicationContext.Provider>
    );
};
