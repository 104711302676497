import React, {useMemo} from 'react';
import {
    StyledTutorialButton,
    ButtonInterface,
} from 'contexts/helpers/TutorialPreviousButton';

export const TutorialCloseButton = ({
    currentStep,
    setCurrentStep,
    stepsLength,
    setIsOpen,
}: ButtonInterface) => {
    const handleClick = () => {
        setCurrentStep(currentStep + 1);
    };

    const nextButton = useMemo(() => {
        if (stepsLength > 1 && currentStep < stepsLength - 1) {
            return (
                <StyledTutorialButton onClick={handleClick}>
                    <span>Next</span>
                </StyledTutorialButton>
            );
        }
    }, [stepsLength, currentStep]);

    return (
        <>
            {nextButton}
            <StyledTutorialButton onClick={() => setIsOpen(false)}>
                <span>Close</span>
            </StyledTutorialButton>
        </>
    );
};
