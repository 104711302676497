import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {Loader} from 'shared/helpers';
import {useConfirmationDialog} from 'shared';
import {
    useNavigate,
    useLocation,
    useMatch,
    matchPath,
    useSearchParams,
} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {useAppContext} from 'contexts';
import {useLazyGetJobQuery} from 'components/customer/Job/store/jobApi';
import {produce} from 'immer';

export const JobContext = createContext();

export const useJobContext = () => useContext(JobContext);

export const JobProvider = ({children, jobId, roomId}) => {
    const {userProfile} = useAppContext();
    const {showDialog, dialog, hideDialog} = useConfirmationDialog();
    const navigate = useNavigate();
    const location = useLocation();

    const [isJobProcessing, setIsJobProcessing] = useState(true);
    const [loader, setLoader] = useState(false);
    const [room, setRoom] = useState({});
    const [isPageDirty, setIsPageDirty] = useState([]);

    const [searchParams] = useSearchParams();

    const isJobDashboardPage = useMatch('/v2/job/:jobId/dashboard');

    const [job, setJob] = useState({});

    const [getJobDetails, {data: jobData, isLoading, isFetching, error}] =
        useLazyGetJobQuery();

    const expiredJobPrice = useMemo(() => {
        return job.priceExpire;
    }, [job]);

    const navigateToRoom = useCallback(
        (jobId, roomId) => {
            navigate(`/v2/job/${jobId}/room/${roomId}/dashboard`);
        },
        [navigate]
    );

    useEffect(() => {
        setIsJobProcessing(true);
        if (jobId && !isNaN(jobId)) {
            getJobDetails({
                jobId: parseInt(jobId) - 10000,
                timestamp: Date.now(), // this is used to force a re-fetch. It's not used in the API
            });
        }
    }, [jobId]);

    useEffect(() => {
        setLoader(isLoading || isFetching);
    }, [isLoading, isFetching]);

    useEffect(() => {
        if (job && job.rooms && job.rooms.length > 0) {
            if (roomId) {
                const roomDetails = job.rooms.find((room) => room.id == roomId);

                if (typeof roomDetails !== 'undefined') {
                    setRoom(cloneDeep(roomDetails));
                }
            } else {
                if (
                    !!matchPath(
                        '/v2/job/:jobId/room/:roomId/*',
                        location.pathname
                    )
                ) {
                    setRoom(cloneDeep(job.rooms[0]));
                }
            }
        }
    }, [job, roomId]);

    useEffect(() => {
        if (jobData && !error) {
            setIsJobProcessing(jobData.status == 0);
            setJob(cloneDeep(jobData));

            if (
                isJobDashboardPage &&
                roomId == null &&
                jobData.rooms &&
                jobData.rooms.length > 0
            ) {
                const roomDetails = jobData.rooms[0];

                if (roomDetails) {
                    navigateToRoom(jobData.displayId, roomDetails.id);
                }
            }
        } else if (error) {
            setJob({error});
        }
    }, [jobData, error]);

    useEffect(() => {
        if (searchParams.has('deleted-room')) {
            const deletedRoomId = searchParams.get('deleted-room');

            if (job && job.rooms && job.rooms.length > 0) {
                const deletedRoom = job.rooms.find(
                    (room) => room.id == deletedRoomId
                );

                if (
                    typeof deletedRoom === 'undefined' &&
                    deletedRoomId == room.id
                ) {
                    const room = job.rooms[job.rooms.length - 1];

                    navigateToRoom(job.displayId, room.id);
                }
            }
        }
    }, [searchParams, job, room]);

    const actionButtonsEnabled = useMemo(() => {
        const enableButton =
            isJobProcessing && userProfile?.inActiveManufacturer == 0;
        const expiredJob = !userProfile.show_pricing ? false : expiredJobPrice;

        return enableButton && !expiredJob;
    }, [isJobProcessing, userProfile, expiredJobPrice]);

    const updateTotalProductCountManual = useCallback(
        (isAddedItemBenchtop = true, increment = true) => {
            setJob(
                produce(job, (draft) => {
                    draft.totalProductCount += increment ? 1 : -1;

                    if (!isAddedItemBenchtop) {
                        const room = draft.rooms.find(
                            (room) => room.id === roomId
                        );
                        if (room) {
                            room.productCount += increment ? 1 : -1;
                        }
                    }
                })
            );
        },
        [job, roomId]
    );

    return (
        <JobContext.Provider
            value={{
                job,
                setJob,
                room,
                setRoom,
                isPageDirty,
                setIsPageDirty,
                showDialog,
                hideDialog,
                isJobProcessing,
                actionButtonsEnabled,
                setLoader,
                expiredJobPrice,
                updateTotalProductCountManual,
            }}>
            <Loader loader={loader}>{children}</Loader>
            {dialog}
        </JobContext.Provider>
    );
};
