import React, {createContext, useContext, useReducer} from 'react';
import {cloneDeep} from 'lodash';
import {useConfirmationDialog} from 'shared';

export const KDMaxContext = createContext();

export const useKDMaxContext = () => useContext(KDMaxContext);

/**
 *
 * This component is used for persisting application data during a session. This component sits on the highest level
 * in component hierarchy so any data persisted in this component is available throughout the application.
 *
 * @param {*} children
 *
 * @return {KDMaxContext.Provider}
 */
export const KDMaxProvider = ({children}) => {
    const [cabinets, setCabinets] = useReducer((state, action) => {
        switch (action.type) {
            case 'add':
                return action.payload;
            case 'delete':
                return cloneDeep(state).filter(
                    (cabinet) => cabinet.id != action.payload
                );
            case 'deleteAll':
                return [];
            case 'refresh':
                return cloneDeep(state);
            default:
                throw new Error();
        }
    }, []);

    const {showDialog, dialog, hideDialog} = useConfirmationDialog();

    return (
        <KDMaxContext.Provider
            value={{
                cabinets,
                setCabinets,
                showDialog,
                dialog,
                hideDialog,
            }}>
            {children}
        </KDMaxContext.Provider>
    );
};
